import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import GradientLineLeft from 'assets/svg/GradientLineLeft'
import GradientLineRight from 'assets/svg/GradientLineRight'
import LoginHeader from 'components/LoginHeader'
import LoginSignupForm from 'components/LoginSignupForm'
import { GetServerSideProps, NextApiRequest, NextApiResponse, NextComponentType } from 'next/types'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Head from 'next/head'
import { withAuthMiddleware } from 'utils/withAuthMiddleware'
import { getAccessToken } from 'utils/authMiddleware'
import GoogleLoginButton from 'components/LoginSignupForm/GoogleLoginButton'

const LoginPage: NextComponentType = () => {
  const router = useRouter()

  const redirectUrlSearchParams = new URLSearchParams(router.query as Record<string, string>)
  redirectUrlSearchParams.delete('redirect_uri')
  const redirectUrl = router.query?.redirect_uri
    ? `${router.query?.redirect_uri}?${redirectUrlSearchParams}`
    : '/'

  useEffect(() => {
    trackEvent('Section Viewed', 'track', {
      sub_category: 'login-email_form',
      category: 'login',
      type: 'form',
      view_type: 'default',
    })
    trackEvent('Section Viewed', 'track', {
      sub_category: 'login-social_google',
      category: 'login',
      type: 'form',
      view_type: 'default',
    })
  }, [])

  return (
    <>
      <Head>
        <title> Rario Login</title>
      </Head>
      <LoginHeader />
      <FlexBox px={'15px'} flexDirection={'column'} pt={3}>
        <TextField
          fontSize={'24px'}
          fontWeight={600}
          lineHeight="29px"
          fontFamily={theme.fonts.primary}
          pb={2}
        >
          {'Continue using email'}
        </TextField>
        <GoogleLoginButton redirectUrl={redirectUrl} />
        <FlexBox justifyContent={'center'} alignItems={'center'} flexDirection={'row'} my={5}>
          <GradientLineLeft />
          <TextField
            fontSize={'12px'}
            fontWeight={400}
            lineHeight="16px"
            fontFamily={theme.fonts.secondary}
            pb={0}
            px={3}
            opacity={0.7}
          >
            or
          </TextField>
          <GradientLineRight />
        </FlexBox>
        <LoginSignupForm redirectTo={redirectUrl} />
      </FlexBox>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = withAuthMiddleware({
  getServerSideProps: async ({ req, res }) => {
    let accessTokenResult = null
    let accessToken = null

    try {
      accessTokenResult = await getAccessToken(req as NextApiRequest, res as NextApiResponse)
      accessToken = accessTokenResult.accessToken
      if (accessToken) {
        return {
          redirect: {
            destination: `/`,
            permanent: false,
          },
        }
      }
    } catch (error) {
      // errorCode = 500
    }

    return {
      props: {},
    }
  },
})

export default LoginPage
